module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://216774b8f54e486bbec5b9a195eee097@o416404.ingest.sentry.io/5934682"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
